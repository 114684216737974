import React from 'react';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { Carousel } from 'react-responsive-carousel';
import { flattenToAppURL } from '@plone/volto/helpers';

const CarouselClient = ({ items, isEditMode = false, size = 'mini' }) => {
  const sizeTranslationSemToPlone = {
    mini: 'thumb',
    tiny: 'mini',
    small: 'preview',
    medium: 'teaser',
    large: 'large',
    big: 'larger',
    huge: 'great',
    massive: 'huge',
  };

  return (
    <div className={'client-carousel ' + size}>
      <Carousel
        infiniteLoop
        emulateTouch
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        centerMode
        centerSlidePercentage={20}
      >
        {items.map((item, index) => (
          <ConditionalLink key={index} item={item} condition={!isEditMode}>
            <Image
              src={flattenToAppURL(
                item['@id'] +
                  '/@@images/image/' +
                  sizeTranslationSemToPlone[size],
              )}
              key={index}
              alt={item.title}
              size={size}
            />
          </ConditionalLink>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselClient;
