import React from 'react';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { Map } from '@eeacms/volto-openlayers-map/Map';
import { Layers, Layer } from '@eeacms/volto-openlayers-map/Layers';
import { Overlays } from '@eeacms/volto-openlayers-map/Overlays';
import { Interactions } from '@eeacms/volto-openlayers-map/Interactions';
import { Icon, Image } from 'semantic-ui-react';
import logo from '@root/assets/images/Logo.svg';
import { useClient } from '@affinitic/volto/hooks';

const { source, proj } = openlayers;

const Body = ({ data, id, isEditMode, intl }) => {
  const client = useClient();
  if (client) {
    // affinitic coord
    //view 4.3573,50.5897
    //pin 4.3573,50.58174

    let view_lat = data.view_lat;
    let view_long = data.view_long;
    let pin_lat = data.pin_lat;
    let pin_long = data.pin_long;

    if (!data.view_long) {
      view_long = data?.pin_long;
    }
    if (!data.view_lat) {
      view_lat = data?.pin_lat;
    }

    const viewCoord = proj.fromLonLat([view_long, view_lat]);
    const pinCoord = proj.fromLonLat([pin_long, pin_lat]);
    return (
      <div className="OSM-banner-map full-width">
        <Map view={{ center: viewCoord, zoom: data.zoom }}>
          <Layers>
            <Layer.Tile
              source={
                new source.OSM({
                  url: 'https://osm.affinitic.be/osm/{z}/{x}/{y}.png',
                  attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                  crossOrigin: null,
                })
              }
            />
          </Layers>
          <Overlays positioning="bottom-center" position={pinCoord}>
            <a href={data.url && data.url} target="_blank" rel="noreferrer">
              <Icon name="point" size="huge" color="blue" />
              <Image className="logo" src={logo} size="mini" />
            </a>
          </Overlays>
          <Interactions mouseWheelZoom={false} keyboard={true} />
        </Map>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Body;
