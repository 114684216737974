import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import FigureStatistics from './FigureStatistics';
import FiguresListData from './FiguresListData';

const Edit = ({ data, onChangeBlock, block, selected }) => {
  return (
    <div>
      <FigureStatistics data={data} />
      <SidebarPortal selected={selected}>
        <FiguresListData
          key={block}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
