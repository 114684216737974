import React from 'react';
import {
  Container,
  List,
  Grid,
  Card,
  Icon,
  Image,
  Loader,
} from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { UniversalLink, DefaultView } from '@plone/volto/components';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { CarouselClient } from '@root/components';

const ClientItem = ({ client }) => {
  return client ? (
    <UniversalLink className="client" href={client.token}>
      <Image
        className="client-logo"
        src={flattenToAppURL(client.token + '/@@images/image/thumb')}
        alt={client.title}
        size="small"
      />
    </UniversalLink>
  ) : (
    <Loader inverted />
  );
};

const ProductView = (props) => {
  const { content } = props;

  return (
    <Container>
      {content.items && (
        <div className="main-carousel">
          <Carousel
            emulateTouch
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showIndicators={content.items.length > 1}
            dynamicHeight={true}
          >
            {content.items.map((image, index) => (
              <img
                key={index}
                alt={image.title}
                src={
                  flattenToAppURL(image.url) +
                  '/@@images/image/project_carousel'
                }
              />
            ))}
          </Carousel>
        </div>
      )}
      {content.image && (
        <Image
          className="project-logo"
          src={flattenToAppURL(content.image.scales.mini.download)}
          size="small"
        />
      )}
      <DefaultView {...props}>
        <div className="metadata">
          {content.website_url && (
            <a href={content.website_url} className="website_url">
              {content.website_url}
            </a>
          )}
          {content.parent.title && (
            <>
              <div className="client-label">Clients :</div>
              <div className="client-list">
                {content.clients.length < 5 ? (
                  <div className="client-static">
                    {content.clients.map((client) => (
                      <ClientItem client={client} />
                    ))}
                  </div>
                ) : (
                  <CarouselClient
                    items={content.clients.map((client) => ({
                      '@id': client.token,
                      title: client.title,
                    }))}
                    size="tiny"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </DefaultView>
    </Container>
  );
};

export default ProductView;
