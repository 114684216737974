export const TeamsItem = {
  title: 'Teams Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'person_function'],
    },
  ],
  properties: {
    title: {
      title: 'Name',
    },
    person_function: {
      title: 'Function',
    },
  },
  required: ['title', 'person_function'],
};

export const JoinUs = {
  title: 'Join us',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'url'],
    },
  ],
  properties: {
    title: {
      title: 'Body',
    },
    url: {
      title: 'URL',
      widget: 'url',
    },
  },
  required: ['title', 'url'],
};

export const TeamsSchema = (props) => {
  return {
    title: 'Teams',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['teamsItem', 'joinUs'],
      },
    ],
    properties: {
      teamsItem: {
        title: 'Teams Item',
        widget: 'object_list',
        schema: TeamsItem,
      },
      joinUs: {
        title: 'Join Us',
        widget: 'object_list',
        schema: JoinUs,
      },
    },
    required: [],
  };
};

export default TeamsSchema;
