import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Reveal, Image, Divider } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

const ValuesBody = ({ data }) => {
  if (__CLIENT__) {
    if (window.matchMedia('(any-hover: none)').matches) {
      return (
        <Card.Group>
          {data.valueItem?.map((result, index) => (
            <Card key={index}>
              <div className="cover">
                <Image
                  src={
                    flattenToAppURL(result.image?.[0]?.['@id']) +
                    '/@@images/image'
                  }
                  size="medium"
                />
              </div>
              <Card.Content>
                <Card.Header>{result.title}</Card.Header>
                <Card.Description className="tactile">
                  {result.description}
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      );
    }
  }

  return (
    <Card.Group itemsPerRow={2} className="Values">
      {data.valueItem?.map((result, index) => (
        <Card key={index}>
          <Card.Content textAlign="center">
            <Reveal animated="move up">
              <Reveal.Content visible>
                <div className="cover">
                  <Image
                    src={
                      flattenToAppURL(result.image?.[0]?.['@id']) +
                      '/@@images/image'
                    }
                    size="medium"
                  />
                </div>
                <Divider />
                <div className="header">{result.title}</div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <div className="description">{result.description}</div>
              </Reveal.Content>
            </Reveal>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};

export default ValuesBody;
