import React from 'react';
import { Card, Container, Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL, getParentUrl } from '@plone/volto/helpers';

// function that take getURL remove id and return url and client_logo
const getClientLogo = (item) => {
  const clientLogo = item.client_logo;
  const url = item.getURL;
  const urlClient = url?.substring(0, url?.lastIndexOf('/'));
  const clientLogoUrl = `${urlClient}/view/++widget++form.widgets.ILeadImageBehavior.image/@@download/${clientLogo}`;
  return clientLogoUrl;
};

const ProjectsListingTemplate = ({ items, isEditMode }) => {
  return (
    <Container id="page-home" className="projects-listing">
      <section id="content-core">
        <Card.Group itemsPerRow={3}>
          {items.map((item, index) => {
            return (
              <a key={index} href={item['@id']}>
                {item.getURL && item.first_image_content && (
                  <div className="background-image-warper">
                    <img
                      className="background-image"
                      src={`${flattenToAppURL(item['@id'])}/${
                        item.first_image_content
                      }/@@images/image/preview`}
                      alt={item.first_image_content}
                    />
                  </div>
                )}
                <Card>
                  <Card.Header>{item.title}</Card.Header>
                  <Card.Description>
                    <div className="year">{item.project_creation_date}</div>

                    <Image
                      src={`${flattenToAppURL(
                        getParentUrl(item['@id']),
                      )}/@@images/image/mini`}
                      size="mini"
                    />
                  </Card.Description>
                </Card>
              </a>
            );
          })}
        </Card.Group>
      </section>
    </Container>
  );
};

export default ProjectsListingTemplate;
