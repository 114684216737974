import React from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent, getContent } from '@plone/volto/actions';

export const getClientLogo = (content) => {
  const getContentSubrequests = useSelector(
    (state) => state.content.subrequests,
  );
  const dispatch = useDispatch();
  const results_getContent = getContentSubrequests?.[content.id]?.data;
  React.useEffect(() => {
    dispatch(
      getContent(
        flattenToAppURL(content.parent['@id']),
        null,
        content.id,
        true,
      ),
    );
  }, [dispatch, content.parent['@id']]);

  return results_getContent?.image?.download;
};

export const getFullObject = (content) => {
  const getContentSubrequests = useSelector(
    (state) => state.content.subrequests,
  );
  const dispatch = useDispatch();
  const results_getContent = getContentSubrequests?.[content.id]?.data;

  React.useEffect(() => {
    dispatch(
      getContent(flattenToAppURL(content['@id']), null, content.id, true),
    );
  }, [dispatch, content['@id']]);

  return results_getContent;
};

export const removeSpaceFromAnchor = (anchor) => {
  if (anchor === undefined) {
    anchor = '';
  }

  return anchor
    .replace(/ +/g, '-')
    .replace(/[éè]/g, 'e')
    .replace(/[à]/g, 'a')
    .replace(/[ç]/g, 'c')
    .replace(/[',;.:/]/g, '_');
};
