import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { HashLink } from 'react-router-hash-link';
import { removeSpaceFromAnchor } from '~/helpers';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -200;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const ServicesListBody = ({ data, id, isEditMode, intl }) => {
  return (
    <Card.Group itemsPerRow={2} className="ServicesList">
      {data.serviceItem?.map((result, index) => (
        <Card key={index}>
          <HashLink
            key={result.id}
            to={
              result.url?.[0]?.['@id'] +
              '#' +
              removeSpaceFromAnchor(result.anchor)
            }
            smooth
            scroll={(el) => scrollWithOffset(el)}
          >
            <Card.Content>
              <Card.Header>
                <div className="header-content">{result.title}</div>
                <Icon icon name="plus circle" />
              </Card.Header>
            </Card.Content>
          </HashLink>
        </Card>
      ))}
    </Card.Group>
  );
};

export default ServicesListBody;
