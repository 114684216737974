export const FigureItem = {
  title: 'Figure Item',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['label', 'amount'],
    },
  ],
  properties: {
    label: {
      title: 'Label',
    },
    amount: {
      title: 'Amount',
    },
  },
  required: ['label', 'amount'],
};

export const FiguresListSchema = (props) => {
  return {
    title: 'Figure List',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['figuresPerLine', 'figureItem'],
      },
    ],
    properties: {
      figureItem: {
        title: 'Figure Item',
        widget: 'object_list',
        schema: FigureItem,
      },
      figuresPerLine: {
        title: 'Figures per line',
        default: 'three',
        choices: [
          ['two', 'two'],
          ['three', 'three'],
          ['four', 'four'],
        ],
      },
    },
    required: [],
  };
};

export default FiguresListSchema;
