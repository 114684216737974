import React from 'react';
import { BlockDataForm } from '@plone/volto/components';
import { MapBannerSchema } from './schema';

const BannerData = (props) => {
  const { data, block, onChangeBlock } = props;
  const schema = MapBannerSchema({ ...props });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};

export default BannerData;
