import React from 'react';
import { Container, List, Grid, Card, Icon, Image } from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getClientLogo } from '../../helpers';
import { ConditionalLink } from '@plone/volto/components';

const checkTestimonialsContent = (content) => {
  return content.testimonial.data !== '<p/>';
};

const ProjectView = (props) => {
  const { content } = props;
  const clientLogo = getClientLogo(content);
  return (
    <Container>
      {content.items && (
        <Carousel
          emulateTouch
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={content.items.length > 1}
          dynamicHeight={true}
        >
          {content.items.map((image, index) => (
            <img
              key={index}
              alt={image.title}
              src={
                flattenToAppURL(image.url) + '/@@images/image/project_carousel'
              }
            />
          ))}
        </Carousel>
      )}
      {content.image && (
        <Image
          className="project-logo"
          src={flattenToAppURL(content.image.scales.mini.download)}
          size="small"
        />
      )}
      <div className="header">
        <h1 className="title">{content.title}</h1>
        <span className="year">{content.project_creation_date}</span>
      </div>
      <div className="metadata">
        {content.website_url && (
          <a href={content.website_url} className="website_url">
            {content.website_url}
          </a>
        )}
        {content.parent.title && (
          <>
            <div className="client-label">Client :</div>
            <ConditionalLink
              className="client"
              to={content.parent['@id']}
              condition={true}
            >
              {clientLogo && (
                <Image
                  className="client-logo"
                  src={flattenToAppURL(
                    content.parent['@id'] + '/@@images/image/mini',
                  )}
                  size="mini"
                />
              )}
            </ConditionalLink>
          </>
        )}
      </div>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: content.description }}
      />
      <Grid className="service_techno_list">
        {content.service_list && (
          <Grid.Column>
            <h3>Services</h3>
            <List bulleted>
              {content.service_list.map((service, index) => (
                <List.Item key={index}>{service.title}</List.Item>
              ))}
            </List>
          </Grid.Column>
        )}
        {content.techno_list && (
          <Grid.Column>
            <h3>Technologies</h3>
            <List bulleted>
              {content.techno_list.map((techno, index) => (
                <List.Item key={index}>{techno.title}</List.Item>
              ))}
            </List>
          </Grid.Column>
        )}
      </Grid>
      {content.testimonial && checkTestimonialsContent(content) && (
        <div className="testimonials">
          <Card>
            <Icon name="quote left" />
            <Card.Content>
              <Card.Description>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.testimonial.data,
                  }}
                />
              </Card.Description>
            </Card.Content>
          </Card>
          {content.person_name && content.person_title && (
            <div className="testimonial-author">
              {content.person_name}, {content.person_title}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default ProjectView;
