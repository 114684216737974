import React from 'react';
import { Statistic } from 'semantic-ui-react';

const FigureStatistics = ({ data }) => {
  return (
    <div className={'figure'}>
      {data.figureItem?.length > 0 ? (
        <Statistic.Group widths={data.figuresPerLine || 'three'}>
          {data.figureItem.map((figure, index) => (
            <Statistic key={index}>
              <Statistic.Value>{figure.amount}</Statistic.Value>
              <Statistic.Label>{figure.label}</Statistic.Label>
            </Statistic>
          ))}
        </Statistic.Group>
      ) : (
        'Figures will be displayed here.'
      )}
    </div>
  );
};

export default FigureStatistics;
