import React from 'react';
import { Container, Card, Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

import config from '../../config';

const ClientView = (props) => {
  const { content } = props;

  return (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.image?.download && (
        <Image src={content.image.download} size="small" floated="right" />
      )}
      {content.url_client && <div className="url">{content.url_client}</div>}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      <h3>Projets : </h3>
      <Card.Group itemsPerRow={3} className="project-list">
        {content.items.map((item, index) => (
          <ConditionalLink key={index} item={item} condition={true}>
            <Card>
              <Card.Header>{item.title}</Card.Header>
            </Card>
          </ConditionalLink>
        ))}
      </Card.Group>
    </Container>
  );
};

export default ClientView;
